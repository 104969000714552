"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export default function ShopLayout({ children }: { children: React.ReactNode }) {
    return (
        <QueryClientProvider client={queryClient}>
            <div className="mb-16 flex">
                <div className="mx-auto flex w-full  gap-8 text-black dark:text-white">
                    <div className="order-last flex-1 md:order-none">{children}</div>
                </div>
            </div>
        </QueryClientProvider>
    );
}
